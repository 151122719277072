body {
    background: #353d43;
    font-size: 16px;
    color: #353d43;
}

.title {
    font-size: 100px;
    text-align: center;
    font-family: "Bebas Neue", sans-serif;
    background-color: #66fcf1;
    border-radius: 40px;
    margin: 30px 20%;
}

.titlejt {
    /* margin-right: 20%;
  margin-left: 20%; */
    font-weight: bold;
    color: #66fcf1;
    flex: 1;
    padding: 20px;
    font-family: "Nunito";
}

.middle2 {
    display: flex;
    justify-content: center;
    align-items: center;
}

h3 {
    padding-top: 0.25em;
}

.vertical-timeline-element-content {
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
    padding: 2em 3em !important;
}

.date {
    color: rgb(201, 251, 255);
}

#description {
    margin: 1.5em 0 2em 0;
}

.button {
    text-decoration: none;
    padding: 0.5em 1em;
    border-radius: 5px;
    color: white;
}

@media only screen and (max-width: 1700px) {
    .vertical-timeline-element-date {
        display: block !important;
        float: none !important;
        color: rgb(44, 44, 44);
        margin-top: 1.5em;
    }
}